<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Create Runner</h3>
    </div>

    <!-- Content Row -->
    <RunnerForm></RunnerForm>
  </div>
</template>

<script>
import RunnerForm from "./RunnerForm";
export default {
  name: "CreateRunner",
  components: {RunnerForm}
}
</script>

<style scoped>

</style>